<template>
  <component
    :is="buttonType"
    :class="buttonClass"
    @click="handleClick"
    :href="to"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    // label: {
    //   type: String,
    //   required: true,
    // },
    type: {
      type: String,
      default: "primary",
      validator: (value) => ["primary", "secondary", "none"].includes(value),
    },
    to: {
      type: String,
      default: null,
    },
    onclick: {
      type: Function,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      return {
        button: true,
        "button--primary": this.type === "primary",
        "button--secondary": this.type === "secondary",
      };
    },
    buttonType() {
      return this.to ? "a" : "button";
    },
  },
  methods: {
    handleClick(event) {
      if (this.onclick) {
        event.preventDefault();
        this.onclick(event);
      }
    },
  },
};
</script>

<style scoped>
.button {
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  padding: 1.125rem 1.5rem;
  /* font-size: 1.25rem; */
  font-size: clamp(1rem, 0.8936rem + 0.396vw, 1.25rem);
  border-radius: 8px;
  line-height: 14px;
  /* height: 14px; */
  box-sizing: border-box;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .button {
    max-width: max-content;
  }
}

.button--primary {
  background: var(--color-primary);
  color: var(--color-off-white);
}

.button--secondary {
  background: var(--color-off-white);
  color: var(--color-primary);
}
</style>
