export const SCHEDULE = [
  {
    time: "09:00",
    break: true,
    title: "Start of registration",
  },
  {
    time: "9:00–20:00",
    break: true,
    title: "Exhibition “EGG, NUT, SCREAM AND CHORUS”- curated by Ksenia Malykh",
    subtitle: "Special Guest - Alevtina Kakhidze",
  },
  {
    time: "09:30",
    mainStage: {
      title: "Opening ceremony",
    },
  },
  {
    time: "10:00–10:15",
    mainStage: {
      title: "Ignite Speeches",
      speakers: ["Dr. Olena Apchel", "Nariman Dzhelyal", "Dmytro Kuleba"],
    },
  },
  {
    time: "10:20–11:20",
    mainStage: {
      title: "Jesus vs Superman. Or why the West loves Russian suffering myth",
      speakers: ["Prof. Botakoz Kassymbekova"],
    },
    upStage: {
      title: "The Role of Social Media Algorithms in PsyOps and Hybrid Wars",
      speakers: ["Nataliia Romanyshyn"],
    },
  },
  {
    time: "11:25–12:35",
    mainStage: {
      title: "Fact-Checking Seminar",
      speakers: ["Ana Sârbu", "Robert Putzbach"],
      moderators: ["Mariia Zoryk"],
    },
    upStage: {
      title:
        "Seminar: Shifting Narratives Using Social Media and Campaigning for Change",
      speakers: ["Valeriia Voshchevska", "Alexej Hock"],
      moderators: ["Kateryna Rietz-Rakul"],
    },
  },
  {
    time: "12:35–12:50",
    break: true,
    title: "Coffee Break",
  },
  {
    time: "12:50–14:00",
    mainStage: {
      title:
        "Unmasking Inconvenient Reality: The Challenges of Russian Hybrid Warfare in Ukraine, Moldova, and Germany ",
      subtitle: "Panel Discussion",
      speakers: [
        "Anna Romandash",
        "Dr. Yehor Brailian",
        "Dr. Tatiana Cojocari",
        "Dr. Veronika Solopova",
      ],
      moderators: ["Dmytro Iarovyi"],
    },
  },

  {
    time: "14:00–15:00",
    break: true,
    title: "Lunch Break",
  },

  {
    time: "15:00–15:20",
    mainStage: {
      title:
        "How to Win an Information War: lessons from Britain’s fight against Germany in WWII to confronting Russia today",
      speakers: ["Peter Pomerantsev"],
    },
  },
  {
    time: "15:20–16:10",
    mainStage: {
      title:
        "How Russian disinformation hurts Africa, threatens Europe, and demeans the West",
      speakers: ["Philip Obaji"],
    },
  },
  {
    time: "16:15–17:15",
    mainStage: {
      title: "Memes as an act of disobedience",
      subtitle: "The Role of Memes in post-modern mass communication",
      speakers: ["Dr. Anton Liagusha"],
    },
    upStage: {
      title:
        "From forged documents to AI: how psyops have evolved and which traditional KGB tools are still used by Russia and its allies today",
      speakers: ["Dietmar Pichler"],
    },
  },

  {
    time: "17:15–17:30",
    break: true,
    title: "Coffee Break",
  },

  {
    time: "17:30–18:35",
    mainStage: {
      title: "Myth deconstruction: Red lines of russian state PR",
      subtitle: "Tactics and strategies of russian propaganda",
      speakers: [
        "Oleksii Kovzhun",
        "Nouran Alghamian",
        "Philip Obaji",
        "Arndt Freytag von Loringhoven",
      ],
      moderators: ["Iryna Shulikina"],
    },
  },
  {
    time: "18:35–19:25",
    mainStage: {
      title: "40 minutes of propaganda*",
      subtitle: "*special presentation",
      speakers: [
        "Olesia Saienko",
        "Anna Potyomkina",
        "Catenation",
        "fantastic little splash",
        "Valeria Barbas",
        "Ksenia Malykh",
      ],
      moderators: ["Kateryna Tarabukina"],
    },
  },
  {
    time: "19:30",
    break: true,
    title: "DJ set by UDDA & Networking",
  },
];
