<template>
  <header>
    <div class="container">
      <nav class="menu">
        <button class="toggle-mobile-menu mobile" @click="toggleMobileMenu">
          <MenuIcon />
        </button>
        <router-link to="/">
          <img
            class="logo"
            src="@/assets/images/truth-to-justice-logo.svg"
            alt="Logo"
          />
        </router-link>
        <ul :class="['nav-links', { 'nav-links--mobile': isMobileMenuOpen }]">
          <li v-for="(item, index) in filteredNavigation" :key="index">
            <router-link :to="item.route" @click="closeMobileMenu">{{
              $t(`${item.title}`)
            }}</router-link>
          </li>
        </ul>
      </nav>

      <nav class="buttons">
        <!-- <LanguageSwitcher /> -->
        <BaseButton to="/en/conference#program">{{
          $t("nav.program")
        }}</BaseButton>
      </nav>
    </div>
  </header>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { getNavigation } from "@/data/nav";
// import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";
import BaseButton from "./BaseButton.vue";
import { MenuIcon } from "lucide-vue-next";

export default {
  name: "AppHeader",
  components: {
    // LanguageSwitcher,
    BaseButton,
    MenuIcon,
  },
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  },
  setup() {
    const route = useRoute();
    const currentLang = computed(() => route.params.lang || "en");
    const navigation = computed(() => getNavigation(currentLang.value));
    const filteredNavigation = computed(() =>
      navigation.value.filter((item) => item.show && item.header)
    );

    return {
      filteredNavigation,
    };
  },
};
</script>

<style scoped>
header {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 0.625rem 0;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.2s ease;
  left: 0;
  z-index: 9;
}
@media screen and (min-width: 768px) {
  header {
    padding: 1rem 0;
  }
}
.scroll header {
  transform: translateY(-200px);
}

.scroll-up header {
  transform: translateY(0);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.logo {
  width: 94px;
  height: 41px;
}
@media screen and (min-width: 768px) {
  .logo {
    width: 154px;
    height: 66px;
  }
}
.menu,
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttons {
  gap: 2rem;
}
.nav-links {
  display: flex;
  gap: clamp(1rem, 0.5743rem + 1.5842vw, 2rem);
}

@media screen and (min-width: 768px) {
  /* .buttons {
    display: none;
  } */
  .menu {
    gap: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.nav-links--mobile {
  background-color: var(--color-primary);
  color: var(--color-off-white);
  text-align: center;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  padding: 2rem;
  display: block !important;
}
.nav-links--mobile li:not(:last-child) {
  margin-bottom: 1rem;
}

.nav-links--mobile a {
  color: inherit;
}
.nav-links--mobile li {
  display: block;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0;
}
li {
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  /* font-size: 1.25rem; */
  font-size: clamp(1rem, 0.8936rem + 0.396vw, 1.25rem);
}
a {
  text-decoration: none;
  color: var(--color-off-black);
}

.router-link-exact-active {
  color: var(--color-primary);
}

.mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}

.desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .desktop {
    display: block;
  }
}

.toggle-mobile-menu {
  appearance: none;
  background: none;
  border: 0;
  margin-right: 1rem;
}
</style>
