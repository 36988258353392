<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
let lastScrollTop = 0;

window.addEventListener("scroll", () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const app = document.getElementById("app");

  if (scrollTop > 102) {
    app.classList.add("scroll");
  } else {
    app.classList.remove("scroll");
  }

  if (scrollTop > lastScrollTop) {
    app.classList.add("scroll-down");
    app.classList.remove("scroll-up");
  } else {
    app.classList.add("scroll-up");
    app.classList.remove("scroll-down");
  }

  lastScrollTop = scrollTop <= 102 ? 102 : scrollTop;
});
</script>

<style>
:root {
  --color-primary: rgba(0, 70, 221, 1);
  --color-off-black: rgba(22, 23, 27, 1);
  --color-off-white: rgba(255, 255, 255, 1);
  --color-violet: rgba(155, 0, 221, 1);

  --color-gray: rgba(228, 228, 228, 1);
  --color-deep-gray: rgba(73, 73, 73, 1);

  --main-stage-color: #0041ff;
  --up-stage-color: #a020f0;
}

html {
  scroll-behavior: smooth;
}

#app {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--color-off-black);
  padding-top: 70px;
}
@media screen and (min-width: 768px) {
  #app {
    padding-top: 102px;
  }
}

h1,
h2,
h3,
h4 {
  font-family: "Krona One", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1rem;
}

ul {
  list-style: none;
}

.text {
  margin-bottom: 4rem;
}

.text section,
.text header {
  max-width: 640px;
}

.text h1 {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.text h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.text h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.text h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text p {
  margin-bottom: 1rem;
  line-height: 1.44;
}

.text ol {
  list-style-type: decimal;
  margin-left: 2rem;
  line-height: 1.44;
  margin-bottom: 1rem;
}

.text ul {
  list-style-type: disc;
  margin-left: 2rem;
  line-height: 1.44;
  margin-bottom: 1rem;
}

.text li {
  margin-bottom: 0.5rem;
}
</style>
