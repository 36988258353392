import { createRouter, createWebHistory } from "vue-router";
import { availableLanguages } from "@/utils/constants";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { PATHS } from "@/data/nav";
import SpeakerView from "@/views/SpeakerView.vue";
import SPEAKERS from "@/data/speakers";

const routes = [
  {
    path: PATHS.ROOT,
    redirect: `/${availableLanguages[0]}`, // Redirect to default language
    children: [
      {
        path: PATHS.CONFERENCE,
        redirect: `/${availableLanguages[0]}/${PATHS.CONFERENCE}`, // Redirect /conference to /en/conference
      },
      {
        path: PATHS.ABOUT,
        redirect: `/${availableLanguages[0]}/${PATHS.ABOUT}`, // Redirect /about to /en/about
      },
      {
        path: PATHS.RESEARCH,
        redirect: `/${availableLanguages[0]}/${PATHS.RESEARCH}`, // Redirect /research to /en/research
      },
      {
        path: PATHS.ARTISTIC_RESIDENCY,
        redirect: `/${availableLanguages[0]}/${PATHS.ARTISTIC_RESIDENCY}`, // Redirect /art to /en/art
      },
      {
        path: PATHS.GET_INVOLVED,
        redirect: `/${availableLanguages[0]}/${PATHS.GET_INVOLVED}`, // Redirect /get-involved to /en/get-involved
      },
      {
        path: PATHS.PRIVACY_POLICY,
        redirect: `/${availableLanguages[0]}/${PATHS.PRIVACY_POLICY}`, // Redirect /privacy-policy to /en/privacy-policy
      },
      {
        path: PATHS.IMPRINT,
        redirect: `/${availableLanguages[0]}/${PATHS.IMPRINT}`, // Redirect /imprint to /en/imprint
      },
    ],
  },
  {
    path: "/:lang",
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      const { lang } = to.params;
      if (!availableLanguages.includes(lang)) {
        return next(`/${availableLanguages[0]}`);
      }
      next();
    },
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: PATHS.CONFERENCE,
        name: "conference",
        component: () => import("@/views/ConferenceView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.GET_INVOLVED,
        name: "getInvolved",
        component: () => import("@/views/GetInvolvedView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.ABOUT,
        name: "about",
        component: () => import("@/views/AboutView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.ARTISTIC_RESIDENCY,
        name: "artFellowship",
        component: () => import("@/views/ArtView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.RESEARCH,
        name: "research",
        component: () => import("@/views/ResearchView.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.PRIVACY_POLICY,
        name: "privacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue"),
        meta: { translateRouteParams: true },
      },
      {
        path: PATHS.IMPRINT,
        name: "imprint",
        component: () => import("@/views/ImprintView.vue"),
        meta: { translateRouteParams: true },
      },
      ...SPEAKERS.map((speaker) => ({
        path: `${speaker.url}`,
        name: `speaker-${speaker.url}`,
        component: SpeakerView,
        props: { speaker },
      })),
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      {
        path: "",
        name: "notFound",
        component: () => import("@/views/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
