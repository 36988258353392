<template>
  <section class="speaker">
    <div class="container">
      <!-- TODO: move to separate component -->
      <router-link class="back" :to="`/${currentLang}/conference#speakers`"
        ><ChevronLeft width="24" /> Go back
      </router-link>
      <!-- / -->

      <div class="speaker-wrapper">
        <figure class="speaker-figure">
          <img :src="speaker.image" :alt="speaker.name" class="speaker-img" />
        </figure>

        <div class="speaker-content">
          <header class="speaker-header">
            <h1 class="speaker-name">
              {{ speaker.name }}
            </h1>
            <h2 class="speaker-title">
              {{ speaker.title }}
              <span v-if="speaker.company">at {{ speaker.company }}</span>
            </h2>

            <ul
              v-if="
                speaker.twitter ||
                speaker.linkedin ||
                speaker.instagram ||
                speaker.website
              "
              class="socials"
            >
              <li v-if="speaker.twitter">
                <a
                  :href="speaker.twitter"
                  :title="`${speaker.name} on Twitter`"
                >
                  <TwitterIcon size="24" />
                </a>
              </li>
              <li v-if="speaker.linkedin">
                <a
                  :href="speaker.linkedin"
                  :title="`${speaker.name} on LinkedIn`"
                >
                  <LinkedinIcon size="24" />
                </a>
              </li>
              <li v-if="speaker.instagram">
                <a
                  :href="speaker.instagram"
                  :title="`${speaker.name} on Instagram`"
                >
                  <InstagramIcon size="24" />
                </a>
              </li>
              <li v-if="speaker.website">
                <a
                  :href="speaker.website"
                  :title="`Website of ${speaker.name}`"
                >
                  <Website size="24" />
                </a>
              </li>
            </ul>
          </header>
          <p class="bio-text">{{ speaker.bio }}</p>

          <div v-if="sessions.length">
            <div class="sessions">
              <div
                v-for="session in sessions"
                :key="session.time"
                class="session"
                :class="
                  session.stage === 'main-stage'
                    ? 'main-stage-session'
                    : 'up-stage-session'
                "
              >
                <header class="session-header">
                  <p class="session-time">
                    <Clock size="20" />
                    <time :datetime="session.time" class="time">{{
                      session.time
                    }}</time>
                  </p>
                  <p class="session-stage">
                    <Video v-if="session.stage === 'main-stage'" size="20" />
                    <UserRound v-if="session.stage === 'up-stage'" size="20" />
                    {{ session.stageName }}
                  </p>
                </header>
                <h3 class="speach-title">{{ session.title }}</h3>
                <p class="subtitle">{{ session.subtitle }}</p>
                <BaseButton
                  type="secondary"
                  :to="PATHS.CONFERENCE_REGISTRATION"
                  class="button"
                  target="_blank"
                  >Register to attend
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import {
  LinkedinIcon,
  InstagramIcon,
  TwitterIcon,
  Website,
  ChevronLeft,
  Clock,
  Video,
  UserRound,
} from "lucide-vue-next";
import { SCHEDULE } from "@/data/program";
import SPEAKERS from "@/data/speakers";
import { PATHS } from "@/data/nav";
import BaseButton from "@/components/common/BaseButton.vue";

export default {
  name: "SpeakerView",
  props: {
    speaker: {
      type: Object,
      required: true,
    },
  },
  components: {
    LinkedinIcon,
    InstagramIcon,
    TwitterIcon,
    Website,
    ChevronLeft,
    Clock,
    Video,
    UserRound,
    BaseButton,
  },
  setup(props) {
    const route = useRoute();
    const currentLang = ref(route.params.lang);
    const backLink = ref(`/${currentLang.value}/conference#speakers`);

    const speaker = ref(props.speaker);
    const sessions = ref([]);

    // Check if the user navigated directly to this page
    if (history.state && history.state.back) {
      backLink.value = -1; // Go back to the previous page
    }

    const getSpeakerSessions = (name) => {
      const speakerSessions = [];
      SCHEDULE.forEach((session) => {
        const mainStageSpeakers = session.mainStage?.speakers || [];
        const upStageSpeakers = session.upStage?.speakers || [];

        if (mainStageSpeakers.includes(name)) {
          speakerSessions.push({
            time: session.time,
            title: session.mainStage.title,
            subtitle: session.mainStage.subtitle || "",
            stage: "main-stage",
            stageName: "Truth stage",
          });
        }

        if (upStageSpeakers.includes(name)) {
          speakerSessions.push({
            time: session.time,
            title: session.upStage.title,
            subtitle: session.upStage.subtitle || "",
            stage: "up-stage",
            stageName: "Justice Stage",
          });
        }
      });
      return speakerSessions;
    };

    onMounted(() => {
      if (speaker.value) {
        sessions.value = getSpeakerSessions(speaker.value.name);
      }
    });

    return {
      currentLang,
      backLink,
      // speaker,
      sessions,
    };
  },
  data() {
    return {
      SCHEDULE,
      SPEAKERS,
      PATHS,
    };
  },
};
</script>

<style scoped>
.back {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  line-height: 1;
  color: var(--color-primary);
  text-decoration: none;
  font-size: 1rem;
  text-transform: uppercase;
}

.speaker-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  .speaker-wrapper {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  .speaker-wrapper {
    padding-right: 100px;
    gap: 5rem;
  }
}

.speaker {
  padding: clamp(2rem, 1.1485rem + 3.1683vw, 4rem) 0;
}

.speaker-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.speaker-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.speaker-figure {
  display: block;
  max-width: 100%;
  /* height: 450px; */
  border-radius: 8px;
}
@media screen and (min-width: 768px) {
  .speaker-figure {
    width: 383px;
    height: 383px;
  }
}

.speaker-img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  min-width: 280px;
  min-height: 383px;
  border-radius: inherit;
}
@media screen and (min-width: 768px) {
  .speaker-img {
    min-width: 383px;
  }
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.socials a {
  color: var(--color-primary);
}

.speaker-name {
  font-size: clamp(1.75rem, 0.4994rem + 4.6535vw, 4.6875rem);
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 400;
  color: var(--color-off-black);
}

.speaker-title {
  font-size: clamp(1rem, 0.8936rem + 0.396vw, 1.25rem);
  line-height: 1.5;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: var(--color-primary);
  font-weight: 400;
}

.session {
  padding: 30px;
  border-radius: 8px;
  color: var(--text-light);
  color: var(--color-off-white);

  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.main-stage-session {
  background-color: var(--main-stage-color);
  margin-right: auto;
}
.main-stage-session .button {
  color: var(--main-stage-color);
}

.up-stage-session {
  background-color: var(--up-stage-color);
  margin-left: auto;
}
.up-stage-session .button {
  color: var(--up-stage-color);
}

.session-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  line-height: 20px;
}

.speach-title {
  /* font-size: 1.5rem; */
  font-size: clamp(1.125rem, 0.9653rem + 0.5941vw, 1.5rem);
  margin-bottom: 0;
  line-height: 1.3;
  font-weight: 800;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.session-time,
.session-stage {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.subtitle {
  font-size: clamp(1.125rem, 0.9653rem + 0.5941vw, 1.5rem);
  font-weight: 400;
  opacity: 0.95;
  line-height: 1.3;
}

.button {
  font-weight: 500;
}

.bio-text {
  white-space: pre-line;
}
</style>
